<template>
  <div id="compras" class="mt-4">
    <h4 class="text-primary mt-3 mb-3 font-weight-bold">Compras</h4>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          v-if="perfil == 1"
          class="nav-link"
          id="home-tab"
          data-toggle="tab"
          href="#home"
          role="tab"
          aria-controls="home"
          aria-selected="false"
          >Transacciones</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          id="mapa-tab"
          data-toggle="tab"
          href="#mapa"
          role="tab"
          aria-controls="mapa"
          aria-selected="true"
          >Mapa</a
        >
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        v-if="perfil == 1"
        class="tab-pane fade"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="mt-2 mb-2 row">
          <div class="form-group col-lg-3">
            <label>Filtrar por estado</label>
            <select
              v-model="estado_transaccion"
              v-on:change="get_transacciones"
              class="form-control p-1"
            >
              <option value="-2">Todas</option>
              <option value="0">Pendiente</option>
              <option value="-1">Rechazada</option>
              <option value="1">Aprobada</option>
              <option value="2">Abortada</option>
            </select>
          </div>
          <div class="form-group col-lg-3">
            <label>Buscar</label>
            <div class="input-group">
              <input
                v-model="search"
                v-on:keyup.enter="get_transacciones"
                type="text"
                class="form-control"
              />
              <span class="input-group-append">
                <button
                  v-on:click="get_transacciones"
                  class="file-upload-browse btn btn-secondary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div>
          </div>
          <div class="form-group col-lg-3"></div>
          <div
            v-if="transacciones.length != 0"
            class="form-group col-lg-3 text-right"
          >
            <button v-on:click="download" class="btn btn-success mt-4">
              <i class="fa fa-download"></i>
              Descargar
            </button>
          </div>
        </div>
        <div v-if="loading_transacciones" class="text-center">
          <small class="text-muted"> Cargando datos...</small><br />
          <div class="spinner-border text-info" role="status">
            <span class="sr-only">Cargando datos...</span>
          </div>
        </div>
        <div v-else>
          <div v-if="transacciones.length != 0" class="table-responsive">
            <table
              class="table table-bordered table-condensed table-hover my_table"
            >
              <thead>
                <tr>
                  <th class="text-center">#</th>
                  <th>Fecha de registro</th>
                  <th>Versión</th>
                  <th>ID Compra</th>
                  <th>Cliente</th>
                  <th>Localidad</th>
                  <th class="text-center">Cantidad</th>
                  <th class="text-right">Valor</th>
                  <th class="text-center">Método de pago</th>
                  <th class="text-center">Estado compra</th>
                  <th class="text-center">Estado pasarela</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(transaccion, index_transaccion) in transacciones"
                  v-bind:key="index_transaccion"
                >
                  <td class="text-center">{{ transaccion.num }}</td>
                  <td>{{ transaccion.fecha_reg }}</td>
                  <td class="text-center">
                    <span v-if="transaccion.canal == 1">V1</span>
                    <span v-if="transaccion.canal == 0">V2</span>
                  </td>
                  <td>{{ transaccion.id_compra_shop }}</td>
                  <td>
                    <span class="text-uppercase"
                      >{{ transaccion.nombres }}
                      {{ transaccion.apellidos }}</span
                    ><br />
                    <span class="text-muted">
                      {{ transaccion.email }}<br />
                      {{ transaccion.celular }}
                    </span>
                  </td>
                  <td>{{ transaccion.localidad }}</td>
                  <td class="text-center">{{ transaccion.cantidad }}</td>
                  <td class="text-right">
                    {{ transaccion.valor_total | currency }}
                  </td>
                  <td class="text-center">
                    <span v-if="transaccion.forma_pago == 1"> Efectivo </span>
                    <span v-if="transaccion.forma_pago == 2">Débito</span>
                  </td>
                  <td class="text-center">{{ transaccion.estado_shop }}</td>
                  <td class="text-center">
                    <span
                      v-if="transaccion.estado_transaccion == 0"
                      class="badge badge-warning"
                    >
                      Pendiente
                    </span>
                    <span
                      v-if="transaccion.estado_transaccion == 1"
                      class="badge badge-success"
                    >
                      Aprobada
                    </span>
                    <span
                      v-if="transaccion.estado_transaccion == -1"
                      class="badge badge-danger"
                    >
                      Rechazada
                    </span>
                    <span
                      v-if="transaccion.estado_transaccion == 2"
                      class="badge badge-danger"
                    >
                      Abortada
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mt-4 mb-4" v-html="paginate"></div>
          </div>
          <div v-else>
            <div class="text-center text-muted">
              <i class="fa fa-info-circle"></i> No se encontraron resultados.
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade show active"
        id="mapa"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div v-if="perfil == 1" class="mb-3 text-right">
          <button
            v-if="!form_upload"
            v-on:click="form_upload = !form_upload"
            data-toggle="modal"
            data-target="#subir"
            class="btn btn-success btn-rounded btn-sm ml-1"
          >
            <i class="fa fa-upload"></i> Actualizar mapa
          </button>
        </div>
        <transition v-if="form_upload" appear name="slide-fade">
          <div v-if="form_upload" class="mt-3 mb-3">
            <div class="card card-body border bg-light">
              <div v-if="loading_upload" class="mb-3">
                <small class="text-center">Cargando archivo...</small><br />
                <div class="spinner-border text-info" role="status">
                  <span class="sr-only">Cargando archivo...</span>
                </div>
              </div>
              <form enctype="multipart/form-data" v-on:submit.prevent="upload">
                <h5 class="text-primary font-weight-bold mt-2 mb-3">
                  Subir mapa
                </h5>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="file-es" role="button"
                        >Seleccionar archivo</label
                      >
                      <input
                        id="file-es-soporte"
                        type="file"
                        @change="onFileChange"
                        multiple
                      />
                      <small class="form-text text-muted">
                        Seleccionar archivo .csv.
                      </small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <button
                      :disabled="!hay_archivo"
                      type="submit"
                      class="btn btn-success btn-rounded btn-sm"
                    >
                      <i class="fa fa-upload"></i> Subir
                    </button>
                    <a
                      v-on:click="form_upload = false"
                      class="btn btn-rounded btn-outline-dark btn-sm ml-2"
                    >
                      Cancelar
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </transition>
        <div class="row">
          <div class="col-lg-12">
            <div v-if="loading_mapa" class="text-center">
              <small class="text-muted"> Cargando mapa...</small><br />
              <div class="spinner-border text-info" role="status">
                <span class="sr-only">Cargando mapa...</span>
              </div>
            </div>
            <div v-else>
              <hr />
              <div v-if="mapa.length != 0">
                <div class="text-center">
                  <h4 class="font-weight-bold text-info">
                    {{ evento.evento }}
                  </h4>
                  <h4 class="font-weight-bold text-success">
                    {{ localidad.localidad }}
                  </h4>
                  <h6 class="font-weight-bold">{{ evento.fecha }}</h6>
                  <h6 class="font-weight-bold">{{ evento.lugar }}</h6>
                  <div class="text-center mt-2">
                    <small>
                      <i
                        class="fa fa-circle 2x"
                        style="color: rgb(253, 148, 61)"
                      ></i>
                      Reserva admin -
                      <i class="fa fa-circle 2x"></i>
                      Venta - <i class="fa fa-circle 2x"></i> Venta Web
                      Entregada -
                      <i class="fa fa-circle 2x" style="color: #57b657"></i>
                      Reserva web -
                      <i class="fa fa-circle 2x"></i>
                      Venta web pagada -
                      <i class="fa fa-circle 2x" style="color: #ff4747"></i>
                      Cortesía
                    </small>
                  </div>
                  <div
                    class="card card-body border p-1 mt-4 mb-3 bg-light font-weight-bold"
                  >
                    Tarima
                  </div>
                </div>
                <div class="m-1">
                  <div
                    v-for="(fila, index_fila) in mapa"
                    v-bind:key="index_fila"
                    class="row mb-1"
                  >
                    <div class="col-lg-12 col-xs-12 col-12">
                      <div class="row">
                        <div
                          v-for="(espacio, index_espacio) in fila"
                          v-bind:key="index_espacio"
                          class="col text-center pt-1 pb-1 pl-0 pr-0 font-weight-bold"
                          style="font-size: 8px"
                          v-bind:class="{
                            'border-espacio': espacio.numero != null,
                          }"
                          v-bind:style="
                            'border-color:' + espacio.color_localidad
                          "
                        >
                          <span v-if="espacio.numero != null">
                            <span
                              v-if="espacio.estado_compra == null"
                              class="my-badge text-muted"
                              >{{ espacio.numero }}</span
                            >
                            <span v-else>
                              <span
                                v-bind:class="{
                                  'my-badge badge-orange':
                                    espacio.estado_compra.estado == 2,
                                  'my-badge badge-black':
                                    espacio.estado_compra.estado == 3 ||
                                    espacio.estado_compra.estado == 5 ||
                                    espacio.estado_compra.estado == 7,
                                  'my-badge badge-success':
                                    espacio.estado_compra.estado == 6,
                                  'my-badge badge-danger':
                                    espacio.estado_compra.estado == 8,
                                }"
                              >
                                {{ espacio.numero }}
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <div class="m-1">
                    <div
                      v-for="(fila, index_fila) in mapa"
                      v-bind:key="index_fila"
                      class="row mb-1"
                    >
                      <div class="col-lg-12 col-xs-12 col-12">
                        <div class="row">
                          <div
                            v-for="(espacio, index_espacio) in fila.espacios"
                            v-bind:key="index_espacio"
                            class="col border pt-1 pb-1 pl-0 pr-0 font-weight-bold"
                            style="font-size: 10px"
                          >
                            <span
                              v-if="espacio.estado_compra == null"
                              class="my-badge text-muted"
                              >{{ espacio.numero }}</span
                            >
                            <span v-else>
                              <span
                                v-bind:class="{
                                  'my-badge badge-warning':
                                    espacio.estado_compra.estado == 2,
                                  'my-badge badge-black':
                                    espacio.estado_compra.estado == 3 ||
                                    espacio.estado_compra.estado == 5 ||
                                    espacio.estado_compra.estado == 7,
                                  'my-badge badge-success':
                                    espacio.estado_compra.estado == 6,
                                  'my-badge badge-danger':
                                    espacio.estado_compra.estado == 8,
                                }"
                              >
                                {{ espacio.numero }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="crear_mapa">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-primary">Mapa de localidad</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body p-3">
            <form v-on:submit.prevent="set_mapa">
              <div class="m-1">
                <h5 class="font-weight-bold text-info">
                  {{ localidad.localidad }}
                </h5>
                <h6 class="font-weight-bold">Aforo: {{ localidad.aforo }}</h6>
                <h6 class="font-weight-bold">
                  Espacios: {{ localidad.espacios }}
                </h6>
              </div>
              <hr />
              <div v-if="mapa.length == 0">
                <div v-if="nuevo_mapa.filas.length == 0" class="mt-3 mb-2">
                  <div class="form-group">
                    <label>Filas</label>
                    <input v-model="num_filas" class="ml-1" type="number" />
                    <button
                      :disabled="num_filas == 0 || num_filas == ''"
                      v-on:click="set_filas"
                      class="btn btn-primary btn-sm ml-2"
                    >
                      Agregar
                    </button>
                  </div>
                </div>
                <div v-else>
                  <div class="table-responsive">
                    <table
                      class="table table-condensed table-bordered table-hover"
                    >
                      <thead>
                        <tr>
                          <th>Fila</th>
                          <th>Espacios</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(fila, index_fila) in nuevo_mapa.filas"
                          v-bind:key="index_fila"
                        >
                          <td>{{ fila.index }}</td>
                          <td>
                            <input
                              v-on:change="update_espacios(index_fila)"
                              v-model="fila.espacios"
                              class="ml-1"
                              type="number"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="restantes != 0">
                      <div v-if="restantes < 0">
                        <div class="text-right">
                          <a v-on:click="set_fila" href="javascript:void(0)">
                            <i class="fa fa-plus-circle"></i> Agregar fila
                          </a>
                        </div>
                        <div class="text-danger mt-2">
                          Hay <strong>{{ restantes * -1 }}</strong> espacio(s)
                          pendiente(s).
                        </div>
                      </div>
                      <div v-else>
                        <div class="text-danger mt-2">
                          Sobra(n) <strong>{{ restantes }}</strong> espacio(s)
                          de acuerdo al aforo.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <button
                    :disabled="restantes != 0"
                    type="submit"
                    class="btn btn-success btn-sm btn-rounded"
                  >
                    <i class="fa fa-save"></i> Guardar
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-dark btn-rounded btn-sm light"
                    data-dismiss="modal"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
              <div v-if="mapa_success">
                <div class="alert alert-success">
                  <i class="fa fa-check-circle"></i> Se ha creado el mapa de la
                  localidad.
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Compras",
  metaInfo: {
    title: "Compras del evento - Taqui",
  },
  data() {
    return {
      loading: true,
      loading_upload: false,
      loading_mapa: false,
      loading_transacciones: true,
      id_evento: null,
      localidades: [],
      edit: false,
      id_localidad: 0,
      localidad: [],
      mapa: [],
      num_filas: 3,
      nuevo_mapa: {
        filas: [],
      },
      restantes: 0,
      mapa_success: false,
      form_upload: false,
      file: [],
      hay_archivo: false,
      transacciones: [],
      estado_transaccion: -2,
      page: 1,
      paginate: null,
    };
  },
  props: {
    evento: Object,
    perfil: String,
  },
  created: function () {
    this.id_evento = this.$route.params.id_evento;
    this.page = this.$route.params.page;
    this.get_mapa();
    this.get_localidades();
    this.get_transacciones();
  },
  methods: {
    get_mapa: function () {
      var params = null;
      if (this.id_localidad != null && this.id_localidad != 0) {
        params = {
          id_localidad: this.id_localidad,
        };
      }
      this.loading_mapa = true;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      this.page = this.$route.params.page;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
        params: params,
      };
      axios
        .get(this.$base_url + "eventos/mapa_evento/" + this.id_evento, config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.mapa = response.data.data.mapa;
          } else {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Mapa",
              text: response.data.message,
            });
          }
          this.loading_mapa = false;
        })
        .catch((error) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Mapa",
            text: error,
          });
        });
    },
    get_localidades: function () {
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      this.page = this.$route.params.page;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
      };
      axios
        .get(this.$base_url + "eventos/localidades/" + this.id_evento, config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.localidades = response.data.data;
          }
        })
        .catch()
        .finally();
    },
    set_localidad: function () {
      this.msg_nuevo = {
        type: null,
        msg: null,
      };
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        id_evento: this.id_evento,
        localidad: this.nuevo.localidad,
        vendidas: this.nuevo.vendidas,
      };
      const vm = this;
      axios
        .post(this.$base_url + "eventos/set_localidad", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.nuevo = {
              localidad: null,
            };
            vm.get_localidades();
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Nueva",
              text: "Se ha creado la localidad.",
            });
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Nueva",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Nueva",
            text: error,
          });
        });
    },
    load_editar: function (index) {
      this.localidad = this.localidades[index];
      this.index_select = index;
      this.edit = true;
    },
    update_localidad: function () {
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        id_localidad: this.localidad.id_localidad,
        localidad: this.localidad.localidad,
        vendidas: this.localidad.vendidas,
      };
      const vm = this;
      axios
        .put(this.$base_url + "eventos/update_localidad", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Localidades",
              text: "Se ha actualizado la localidad.",
            });
            vm.edit = false;
            vm.get_localidades();
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Localidades",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Localidades",
            text: error,
          });
        });
    },
    load_eliminar: function (index) {
      this.localidad = this.localidades[index];
    },
    delete_localidad: function () {
      this.msg_eliminar = {
        type: null,
        text: null,
      };
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        id_localidad: this.localidad.id_localidad,
      };
      const vm = this;
      axios
        .put(this.$base_url + "eventos/eliminar_localidad", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.msg_eliminar = {
              type: true,
              text: "Localidad eliminada.",
            };
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Localidades",
              text: "Se ha eliminado la localidad.",
            });
            vm.localidad = [];
            vm.get_localidades();
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Localidades",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Localidades",
            text: error,
          });
        });
    },
    set_filas: function () {
      var i = 1;
      var espacios_fila = Math.ceil(this.localidad.espacios / this.num_filas);
      var add = 0;
      while (i <= this.num_filas) {
        if (i == this.num_filas) {
          espacios_fila = this.localidad.espacios - add;
        }
        this.nuevo_mapa.filas.push({
          index: i,
          espacios: espacios_fila,
        });
        add = add + espacios_fila;
        i++;
      }
    },
    update_espacios: function (index) {
      console.log(index);
      var t_espacios = 0;
      for (let fila of this.nuevo_mapa.filas) {
        t_espacios = parseInt(t_espacios) + parseInt(fila.espacios);
      }
      this.restantes = parseInt(t_espacios) - this.localidad.espacios;
    },
    set_fila: function () {
      var i = parseInt(this.nuevo_mapa.filas.length) + 1;
      this.nuevo_mapa.filas.push({
        index: i,
        espacios: this.restantes * -1,
      });
      this.update_espacios();
    },
    set_mapa: function () {
      this.mapa_success = false;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        id_localidad: this.localidad.id_localidad,
        mapa: this.nuevo_mapa,
      };
      const vm = this;
      axios
        .post(this.$base_url + "eventos/set_mapa", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Mapa",
              text: "Se ha creado el mapa de la localidad.",
            });
            vm.get_mapa();
            vm.mapa_success = true;
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Mapa",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Mapa",
            text: error,
          });
        });
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.hay_archivo = false;
        return;
      }
      this.file = files;
      this.hay_archivo = true;
    },
    upload: function () {
      this.hay_archivo = false;
      this.loading_upload = true;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      var formData = new FormData();
      formData.append("files", this.file[0]);
      formData.append("token", token_session);
      formData.append("id_evento", this.id_evento);
      const vm = this;
      axios
        .post(this.$base_url + "upload/mapa", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status) {
            vm.hay_archivo = false;
            vm.msg_upload.type = true;
            vm.msg_upload.text = " Se importó el mapa correctamente.";
            if (response.data.errores != null) {
              var errores = "";
              for (let error of response.data.errores) {
                errores = errores + "<li>" + error + "</li>";
              }
              vm.msg_upload.text =
                vm.msg_upload.text +
                "<br> <i class='fa fa-exclamation-circle'></i> Se encontraron los siguientes errores en la importación: " +
                errores;
            }
            vm.get_localidades();
            vm.form_upload = false;
            vm.loading_upload = false;
          } else {
            vm.msg_upload = false;
            vm.loading_upload = false;
          }
        })
        .catch((error) => {
          vm.msg_upload = false;
          console.log(error);
          vm.loading_upload = false;
        });
    },
    get_transacciones: function () {
      this.loading_transacciones = true;
      this.transacciones = [];
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      var search = null;
      if (this.search != null && this.search.length >= 3) {
        search = this.search;
      }
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
        params: {
          page: this.page,
          search: search,
          estado: this.estado_transaccion,
        },
      };
      axios
        .get(this.$base_url + "shop/transacciones/" + this.id_evento, config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.transacciones = response.data.data;
            this.loading_transacciones = false;
            this.get_paginate(response.data.total_pages);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        })
        .catch()
        .finally();
    },
    get_paginate: function (tpages) {
      var page = this.page;

      if (page === undefined || page <= 0) {
        this.page = 1;
        page = this.page;
      }

      var adjacents = 4;

      var paginate = null;

      paginate = '<ul class="pagination justify-content-center text-center">';
      // previous label
      if (page == 1) {
        paginate +=
          "<li class='paginate_button page-item previous disabled'><a class='page-link disabled'> <i class='fa fa-chevron-left'></i> </a></li>";
      } else if (page == 2) {
        paginate +=
          "<li class='paginate_button page-item  previous'><a href='#/compras/" +
          this.id_evento +
          "/1' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-left'></i> </a></li>";
      } else {
        var page_ant = parseFloat(page) - 1;
        paginate +=
          "<li class='paginate_button page-item'><a href='#/compras/" +
          this.id_evento +
          "/" +
          page_ant +
          "' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-left'></i> </a></li>";
      }
      // first label
      if (page > parseFloat(adjacents) + 1) {
        paginate +=
          "<li class='paginate_button page-item'><a class='page-link' href='#/compras/" +
          this.id_evento +
          "/1'>1</a></li>";
      }
      // interval
      if (page > parseFloat(adjacents) + 2) {
        paginate += "<li class='paginate_button page-item'> <a> ... </a> </li>";
      }
      // pages
      var pmin =
        page > adjacents ? parseFloat(page) - parseFloat(adjacents) : 1;
      var pmax =
        page < parseFloat(tpages) - parseFloat(adjacents)
          ? parseFloat(page) + parseFloat(adjacents)
          : tpages;

      for (var i = pmin; i <= pmax; i++) {
        if (i == page) {
          paginate +=
            "<li class='paginate_button page-item active'><a href='#/compras/" +
            this.id_evento +
            "/" +
            i +
            "' class='page-link'>" +
            i +
            "</a></li>";
        } else if (i == 1) {
          paginate +=
            "<li class='paginate_button page-item'><a href='#/compras/" +
            this.id_evento +
            "/" +
            i +
            "' class='page-link' href='javascript:void(0);'>" +
            i +
            "</a></li>";
        } else {
          paginate +=
            "<li class='paginate_button page-item'><a href='#/compras/" +
            this.id_evento +
            "/" +
            i +
            "' class='page-link' href='javascript:void(0);'>" +
            i +
            "</a></li>";
        }
      }
      // interval
      if (page < parseFloat(tpages) - parseFloat(adjacents) - 1) {
        paginate += "<li class='paginate_button page-item'> <a> ... </a> </li>";
      }
      // last
      if (page < parseFloat(tpages) - parseFloat(adjacents)) {
        paginate +=
          "<li class='paginate_button page-item'><a  href='#/compras/" +
          this.id_evento +
          "/" +
          tpages +
          "' class='page-link' href='javascript:void(0);'>" +
          tpages +
          "</a></li>";
      }
      // next
      if (page < tpages) {
        var page_next = parseFloat(page) + 1;
        paginate +=
          "<li class='paginate_button page-item next'><a  href='#/compras/" +
          this.id_evento +
          "/" +
          page_next +
          "' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-right'></i> </a></li>";
      } else {
        paginate +=
          "<li class='paginate_button page-item next disabled'><a class='page-link disabled'> <i class='fa fa-chevron-right'></i> </a></li>";
      }
      paginate += "</ul>";
      this.paginate = paginate;
    },
    download: function () {
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;

      window.open(
        this.$base_url +
          "download/transacciones/" +
          token_session +
          "/" +
          this.id_evento +
          "/" +
          this.estado_transaccion,
        "_blank"
      );
    },
  },
  watch: {
    $route() {
      this.page = this.$route.params.page;
      this.get_transacciones();
    },
  },
};
</script>